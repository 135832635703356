import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import PricingTableDynamic from "../blocks/PricingTableDynamic";

const PricingTableLeft = [
  {
    title: "text-center",
    class: "col-lg-4 col-md-6 col-12",
    price: "300",
    rmpricing: "rn-pricing active",
    priceDetail: "Empowering Digital Success",
    description: ["2-Pages Website", "1-revision", "Contact-Us Page"],
  },
  {
    title: "text-center",
    class: "col-lg-4 col-md-6 col-12",
    price: "300",
    rmpricing: "rn-pricing",
    priceDetail: "Empowering Digital Success",
    description: ["2-Pages Website", "1-revision", "Contact-Us Page"],
  },
  {
    title: "text-center",
    class: "col-lg-4 col-md-6 col-12",
    price: "300",
    rmpricing: "rn-pricing",
    priceDetail: "Empowering Digital Success",
    description: ["2-Pages Website", "1-revision", "Contact-Us Page"],
  },
];

class SDMAD extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Service Details" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">WEBITECH</h2>
                  <p>Mobile App Development</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}
        {/* <PricingTableDynamic ptl={PricingTableLeft} /> */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src="/assets/images/service/service-01.png"
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">Building User-Centric Apps</h4>
                          <p>
                            At the heart of every successful app lies a deep
                            understanding of its users. Our mobile app
                            development process is rooted in user-centric design
                            principles. We create apps that are not just
                            visually appealing but also functional, intuitive,
                            and designed to enhance user experiences.
                          </p>
                          <h4 className="title">
                            Blending Creativity with Technology
                          </h4>
                          <p>
                            Our developers strike the perfect balance between
                            creativity and technology. We incorporate
                            cutting-edge tech trends into your app while
                            ensuring it aligns with your brand's identity and
                            business objectives. The result? A unique,
                            high-performing app that stands out in the crowded
                            app marketplace.
                          </p>
                          <h4 className="title">
                            Multi-Platform App Development
                          </h4>
                          <p>
                            Whether you're targeting iOS, Android, or both,
                            Webitech has you covered. Our team has experience
                            building high-quality, multi-platform apps using
                            native and cross-platform development approaches. We
                            ensure your app is optimized for the platform of
                            your choice, offering a seamless user experience.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">Performance-Driven Approach</h4>
                          <p>
                            The performance of your app is crucial for user
                            satisfaction and retention. At Webitech, we adopt a
                            performance-driven approach, rigorously testing each
                            app to ensure it runs smoothly, loads quickly, and
                            remains stable under different conditions.
                          </p>
                          <h4 className="title">
                            Bridging the Gap with App SEO
                          </h4>
                          <p>
                            Mobile app SEO is an often-overlooked aspect of app
                            development. At Webitech, we help bridge this gap.
                            Our SEO experts work alongside our developers to
                            ensure your app is optimized for visibility on app
                            stores, driving downloads and boosting your brand's
                            mobile presence.
                            <br />
                            <br />
                            Here's a 16-word summary of our service: "Webitech
                            crafts user-centric, high-performing mobile apps
                            optimized for SEO, bridging the gap between
                            technology and creativity."
                            <br />
                            <br />
                            For a mobile app that truly resonates with your
                            target audience and stands the test of time, partner
                            with Webitech. We're ready to bring your app idea to
                            life with our unique blend of creativity,
                            technology, and SEO expertise.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src="/assets/images/service/service-02.png"
                            alt="Service Images"
                          />
                          <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="ZOoVOfieAF8"
                            onClose={() => this.setState({ isOpen: false })}
                          />
                          <button
                            className="video-popup"
                            onClick={this.openModal}
                          >
                            <span className="play-icon"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default SDMAD;
