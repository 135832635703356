import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import PricingTableDynamic from "../blocks/PricingTableDynamic";

const PricingTableLeft = [
  {
    title: "text-center",
    class: "col-lg-4 col-md-6 col-12",
    price: "300",
    rmpricing: "rn-pricing active",
    priceDetail: "Empowering Digital Success",
    description: ["2-Pages Website", "1-revision", "Contact-Us Page"],
  },
  {
    title: "text-center",
    class: "col-lg-4 col-md-6 col-12",
    price: "300",
    rmpricing: "rn-pricing",
    priceDetail: "Empowering Digital Success",
    description: ["2-Pages Website", "1-revision", "Contact-Us Page"],
  },
  {
    title: "text-center",
    class: "col-lg-4 col-md-6 col-12",
    price: "300",
    rmpricing: "rn-pricing",
    priceDetail: "Empowering Digital Success",
    description: ["2-Pages Website", "1-revision", "Contact-Us Page"],
  },
];

class SDContentW extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Service Details" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">WEBITECH</h2>
                  <p>Content Writing</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}
        {/* <PricingTableDynamic ptl={PricingTableLeft} /> */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src="/assets/images/service/service-01.png"
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">
                            Storytelling with an SEO Twist
                          </h4>
                          <p>
                            In today's competitive digital world, it's not
                            enough just to tell a good story. Our content
                            writing team specializes in creating compelling
                            narratives that also align with SEO best practices.
                            We strategically incorporate relevant keywords
                            without compromising the flow of the content.
                          </p>
                          <h4 className="title">
                            Content that Engages and Ranks
                          </h4>
                          <p>
                            At Webitech, we know the value of engaging content.
                            Our SEO content writing services aim to not just
                            attract readers but also to keep them engaged. From
                            blog posts to product descriptions, we write content
                            that resonates with your target audience while
                            improving your search engine rankings.
                          </p>
                          <h4 className="title">
                            Website Content that Converts
                          </h4>
                          <p>
                            A well-written website is a powerful sales tool. We
                            employ persuasive writing techniques to create
                            website content that not just attracts visitors but
                            also guides them towards conversion. We craft each
                            word to reflect your brand identity and speak
                            directly to your customers.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">
                            Adapting to Changing SEO Landscape
                          </h4>
                          <p>
                            Search engine algorithms are constantly evolving,
                            and so are we. Our content writers stay abreast with
                            the latest SEO trends to ensure your content always
                            remains SEO-friendly. We adapt our writing
                            strategies to align with current SEO best practices.
                          </p>
                          <h4 className="title">Consistency for Brand Image</h4>
                          <p>
                            Consistent tone and style across all content
                            platforms are key to building a strong brand image.
                            Our content writing team ensures uniformity in all
                            your content pieces, reinforcing your brand
                            identity.
                            <br /> <br />
                            In essence, "Webitech offers SEO-friendly content
                            writing, crafting engaging narratives that help
                            boost your search engine rankings and audience
                            engagement."
                            <br /> <br />
                            Trust Webitech to provide SEO-friendly content
                            writing services that connect with your audience and
                            strengthen your online presence. Choose Webitech,
                            because we don't just write, we craft stories that
                            inspire action.
                          </p>
                          <h4 className="title">Our Working Process</h4>
                          <ul className="liststyle">
                            <li>Yet this above sewed flirted opened ouch</li>
                            <li>Goldfinch realistic sporadic ingenuous</li>
                            <li>
                              Abominable this abidin far successfully then like
                              piquan
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src="/assets/images/service/service-02.png"
                            alt="Service Images"
                          />
                          <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="ZOoVOfieAF8"
                            onClose={() => this.setState({ isOpen: false })}
                          />
                          <button
                            className="video-popup"
                            onClick={this.openModal}
                          >
                            <span className="play-icon"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default SDContentW;
