import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import PricingTableDynamic from "../blocks/PricingTableDynamic";

const PricingTableLeft = [
  {
    title: "Basic",
    class: "col-lg-4 col-md-6 col-12",
    price: "$180",
    rmpricing: "rn-pricing",
    priceDetail: "CAD",
    description: [
      "2 Pages",
      "Contact Form",
      "Fully Secure",
      "SEO Friendly",
      "Hosting Inclued (1st Year only)",
      "Reponsive Design",
      "Speed Optimization",
      "-",
      "-",
      "-",
    ],
  },
  {
    title: "Business",
    class: "col-lg-4 col-md-6 col-12",
    price: "$650",
    rmpricing: "rn-pricing active",
    priceDetail: "CAD",
    description: [
      "4-6 Pages",
      "Contact Form",
      "Fully Secure",
      "SEO Optimized",
      "Hosting Inclued (1st Year only)",
      "Reponsive Design",
      "Speed Optimization",
      "With Animation",
      "2-Landing page designs",
      "Get a panel to update Testimonials",
    ],
  },
  {
    title: "E-Commers",
    class: "col-lg-4 col-md-6 col-12",
    price: "$2,800",
    rmpricing: "rn-pricing",
    priceDetail: "CAD",
    description: [
      "Up to 20 pages",
      "Contact Form",
      "Fully Secure",
      "SEO Optimized (on page)",
      "Hosting Inclued (1st Year only)",
      "With Animation",
      "4-Landing page designs",
      "Get a panel to update Every thingh",
      "Basic Logo Pack",
      "Social Media Business Setup",
    ],
  },
];

class SDWebsiteD extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Service Details" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">WEBSITE DEVELOPMENT</h2>
                  <p>Fresh From The Press Discription</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}
        {/* <PricingTableDynamic ptl={PricingTableLeft} /> */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src="/assets/images/service/service-01.png"
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">
                            Journey Begins with a Concept
                          </h4>
                          <p>
                            The process of website design starts with an idea. A
                            concept, a vision, or a goal, every project has its
                            roots in imagination. We, at Webitech, translate
                            this into an online platform that speaks volumes
                            about your brand.
                          </p>
                          {/* <h3 className="title">Designing the Digital Dream</h3> */}
                          <p>
                            In our pursuit of perfect website design, we ensure
                            your site not only looks beautiful but functions
                            smoothly as well. We create sites that are both
                            appealing to the eye and user-friendly. We believe
                            that a good design doesn't merely catch the viewer's
                            attention but holds it.
                          </p>
                          <h4 className="title">Web Designs that Deliver</h4>
                          <p>
                            The main goal of our website design service is to
                            bring tangible benefits to your business. We aspire
                            to make your website a hub of activity, fostering
                            engagement and driving conversions. The result? A
                            website that serves as a beacon for your brand,
                            attracting customers and fostering growth.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">SEO - A Key Ingredient</h4>
                          <p>
                            No website design is complete without a pinch of
                            SEO. Our designs ensure your website stands out not
                            just to users but to search engines too. When it
                            comes to website design, we keep SEO at the
                            forefront, weaving it into every element.
                          </p>
                          <h4 className="title">
                            Striking Balance in Website Design
                          </h4>
                          <p>
                            To wrap up, the essence of our service lies in
                            balance - the balance between aesthetics and
                            usability, creativity and functionality, uniqueness
                            and SEO-friendliness. In one line, Webitech's
                            website design is your gateway to a compelling and
                            engaging digital presence.
                          </p>
                          <p>
                            This 16-word snapshot encapsulates our service:
                            "Webitech's website design - a unique blend of
                            creativity, functionality, and SEO-optimized
                            aesthetics."
                          </p>
                          <p>
                            Explore the world of Webitech's website design
                            service. Let's create something extraordinary
                            together!
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src="/assets/images/service/service-02.png"
                            alt="Service Images"
                          />
                          <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="ZOoVOfieAF8"
                            onClose={() => this.setState({ isOpen: false })}
                          />
                          <button
                            className="video-popup"
                            onClick={this.openModal}
                          >
                            <span className="play-icon"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default SDWebsiteD;
