import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from "react-modal-video";
// import { FiClock, FiUser, FiMessageCircle, FiHeart } from "react-icons/fi";
import { FiClock, FiUser  } from "react-icons/fi";
// import { Link } from "react-router-dom";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class BlogSeo extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Blog Details" />
        <Header 
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div id="mainTop"
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
          data-black-overlay="7"
        >
          <div className="container" >
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-page-title text-center pt--100">
                  <h2 className="title theme-gradient">
                    The Importance of SEO <br /> For Your Business
                  </h2>
                  <ul className="blog-meta d-flex justify-content-center align-items-center">
                    <li>
                      <FiClock />
                      May 18, 2020
                    </li>
                    <li>
                      <FiUser />
                      Harikant Sharma
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Blog Details */}
        <div className="rn-blog-details pt--110 pb--70 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div className="inner">
                    <p>
                      In today's digital age, having a strong online presence is
                      crucial for businesses of all sizes. With millions of
                      websites competing for attention, it's essential to make
                      sure your website stands out and reaches your target
                      audience. This is where SEO, or Search Engine
                      Optimization, comes into play. In this blog post, we'll
                      explore the importance of SEO and how it can benefit your
                      business.{" "}
                    </p>
                    <div className="thumbnail">
                      <img
                        src="/assets/images/blog/seo.jpg"
                        alt="Blog-seo-webitech"
                      />
                    </div>
                    <p className="mt--40">
                      One of the primary goals of SEO is to improve your
                      website's visibility on search engines. When your website
                      ranks higher in search engine results pages (SERPs), it
                      becomes more likely to attract organic traffic. By
                      optimizing your website's content, keywords, and meta
                      tags, you can enhance its chances of appearing in relevant
                      search queries, ultimately driving more visitors to your
                      site.
                    </p>
                    <p>
                      SEO is not just about optimizing for search engines; it's
                      also about creating a seamless user experience. Search
                      engines like Google consider various factors, such as
                      website speed, mobile-friendliness, and overall user
                      engagement, when ranking websites. By focusing on these
                      aspects of SEO, you can provide your visitors with a
                      positive experience, leading to longer visit durations,
                      lower bounce rates, and increased conversions.
                    </p>
                    <blockquote className="rn-blog-quote">
                      SEO is a strategic practice that aims to optimize websites
                      and online content to improve their visibility and
                      rankings on search engine results pages.{" "}
                    </blockquote>
                    <p>
                      SEO allows you to target specific keywords and phrases
                      that are relevant to your business. By understanding your
                      target audience's search intent, you can optimize your
                      website to align with their needs and preferences. This
                      targeted approach helps you attract highly qualified leads
                      who are more likely to convert into customers.
                      Additionally, SEO enables you to reach potential customers
                      at various stages of the buyer's journey, from awareness
                      to purchase, effectively maximizing your marketing
                      efforts.
                    </p>
                    <div className="blog-single-list-wrapper d-flex flex-wrap">
                      <div className="thumbnail">
                        <img
                          className="w-100"
                          src="/assets/images/blog/blog-single-01.png"
                          alt="BLog-seo-webitech"
                        />
                      </div>
                      <div className="content">
                        <p>
                          Compared to traditional advertising methods, SEO
                          offers a cost-effective marketing strategy. While it
                          may require an initial investment in terms of time and
                          resources, the long-term benefits outweigh the costs.{" "}
                        </p>
                        <h4 className="title">Cost-Effective Marketing.</h4>

                        <p>
                          Compared to traditional advertising methods, SEO
                          offers a cost-effective marketing strategy. While it
                          may require an initial investment in terms of time and
                          resources, the long-term benefits outweigh the costs.
                        </p>

                        <h4 className="title">Competitive Edge.</h4>
                        <p>
                          In today's competitive landscape, SEO is no longer a
                          luxury but a necessity. If your competitors are
                          investing in SEO and you're not, you risk falling
                          behind. By staying ahead of the curve and implementing
                          effective SEO strategies, you can gain a competitive
                          edge in your industry. Ranking higher in search
                          results establishes your business as a trustworthy and
                          authoritative source, instilling confidence in
                          potential customers and setting you apart from your
                          competitors.
                        </p>
                      </div>
                    </div>

                    <p className="mt--25 mt_sm--5">
                      In conclusion, SEO is of utmost importance for businesses
                      in the digital age. By optimizing your website and
                      content, you can increase your visibility on search
                      engines, driving more organic traffic to your site.
                      Additionally, focusing on user experience enhances
                      engagement, reduces bounce rates, and increases
                      conversions. SEO allows you to target specific keywords
                      and phrases, ensuring that your website aligns with the
                      needs and preferences of your target audience. Moreover,
                      by staying ahead of your competitors and establishing your
                      business as a trustworthy and authoritative source, SEO
                      gives you a competitive edge. It is a cost-effective
                      marketing strategy that yields long-term benefits, making
                      it an essential component of your overall digital
                      marketing efforts.
                    </p>
                    {/* <div className="video-wrapper position-relative mb--40">
                                            <div className="thumbnail">
                                                <img src="/assets/images/blog/bl-big-01.jpg" alt="Blog-seo-webitech"/>
                                            </div>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                        <p className="mb--0">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet. It re are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injectedeed eedhumour, or randomised words which don't look even slightly believable.</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        {/* Start BLog Comment Form  */}

        {/* End BLog Comment Form  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default BlogSeo;
