import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
// import ModalVideo from "react-modal-video";
// import { FiClock, FiUser, FiMessageCircle, FiHeart } from "react-icons/fi";
// import { Link } from "react-router-dom";
import { FiClock, FiUser  } from "react-icons/fi";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";

class BlogDigitalMarketing extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        <PageHelmet pageTitle="Blog Digital Marketing" />
        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--7"
          data-black-overlay="7"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="blog-single-page-title text-center pt--100">
                  <h2 className="title theme-gradient">
                    Digital Marketing <br /> Strategies for Success
                  </h2>
                  <ul className="blog-meta d-flex justify-content-center align-items-center">
                    <li>
                      <FiClock />
                      May 18, 2020
                    </li>
                    <li>
                      <FiUser />
                      Harikant Sharma
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}

        {/* Start Blog Details */}
        <div className="rn-blog-details pt--110 pb--70 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="inner-wrapper">
                  <div className="inner">
                    <p>
                      In today's fast-paced digital age, effective marketing is
                      essential for businesses of all sizes. The advent of the
                      internet and the rise of digital platforms have
                      revolutionized the way we connect, engage, and transact
                      with customers. To stay ahead of the competition and reach
                      your target audience, it's crucial to embrace the power of
                      digital marketing. In this blog post, we'll explore key
                      strategies that can help businesses unlock the potential
                      of digital marketing and achieve remarkable success.{" "}
                    </p>
                    <div className="thumbnail">
                      <img
                        src="/assets/images/blog/Digital-marketing.jpg"
                        alt="Blog-seo-webitech"
                      />
                    </div>
                    <p className="mt--40">
                      Before diving into any digital marketing campaign, it's
                      important to clearly define your goals. Are you aiming to
                      increase brand awareness, drive website traffic, generate
                      leads, or boost sales? Understanding your objectives will
                      help you choose the right strategies and metrics to
                      measure success. Remember, SMART goals (Specific,
                      Measurable, Achievable, Relevant, Time-bound) provide a
                      solid foundation for effective digital marketing
                      campaigns.
                    </p>
                    <p>
                      Content is the cornerstone of digital marketing. By
                      creating high-quality, valuable, and relevant content, you
                      can attract and engage your target audience. Start by
                      identifying your buyer personas and understanding their
                      pain points, interests, and preferences. Then, develop a
                      content plan that includes blog posts, social media
                      updates, videos, infographics, and more. Consistency and
                      quality are key here, so ensure your content is optimized
                      for search engines and shareable across various platforms.
                    </p>
                    <blockquote className="rn-blog-quote">
                      Digital marketing is not just about promoting products and
                      services; it's about crafting meaningful experiences,
                      building genuine connections, and empowering customers in
                      the digital realm.{" "}
                    </blockquote>
                    <p>
                      A strong SEO strategy can significantly improve your
                      online visibility and organic traffic. Conduct thorough
                      keyword research to identify the terms and phrases your
                      target audience is searching for. Optimize your website's
                      on-page elements, such as meta tags, headings, and URLs,
                      with relevant keywords. Create high-quality backlinks from
                      reputable websites to boost your search engine rankings.
                      Regularly monitor and analyze your SEO performance to make
                      necessary adjustments and stay ahead in the search
                      results.
                    </p>
                    <div className="blog-single-list-wrapper d-flex flex-wrap">
                      <div className="thumbnail">
                        <img
                          className="w-100"
                          src="/assets/images/blog/blog-single-01.png"
                          alt="BLog-seo-webitech"
                        />
                      </div>
                      <div className="content">
                        <p>
                          Engage with your followers, respond to their queries,
                          and encourage user-generated content. Leverage social
                          media advertising to amplify your reach and target
                          specific demographics.{" "}
                        </p>
                        <h4 className="title">
                          Embrace Social Media Marketing.
                        </h4>

                        <p>
                          With billions of active users, social media platforms
                          provide an excellent opportunity to connect and engage
                          with your audience. Identify the platforms most
                          relevant to your business and create compelling
                          profiles. Develop a social media content calendar and
                          share a mix of promotional and informative content.
                        </p>

                        <h4 className="title">
                          Harness the Power of Email Marketing.
                        </h4>
                        <p>
                          Email marketing remains a highly effective tool for
                          nurturing leads and driving conversions. Build an
                          email list by offering valuable content or exclusive
                          promotions. Segment your audience based on their
                          preferences and behaviors, and send personalized,
                          targeted emails.
                        </p>
                      </div>
                    </div>

                    <p className="mt--25 mt_sm--5">
                      In conclusion, SEO is of utmost importance for businesses
                      in the digital age. By optimizing your website and
                      content, you can increase your visibility on search
                      engines, driving more organic traffic to your site.
                      Additionally, focusing on user experience enhances
                      engagement, reduces bounce rates, and increases
                      conversions. SEO allows you to target specific keywords
                      and phrases, ensuring that your website aligns with the
                      needs and preferences of your target audience. Moreover,
                      by staying ahead of your competitors and establishing your
                      business as a trustworthy and authoritative source, SEO
                      gives you a competitive edge. It is a cost-effective
                      marketing strategy that yields long-term benefits, making
                      it an essential component of your overall digital
                      marketing efforts.
                    </p>
                    {/* <div className="video-wrapper position-relative mb--40">
                                            <div className="thumbnail">
                                                <img src="/assets/images/blog/bl-big-01.jpg" alt="Blog-seo-webitech"/>
                                            </div>
                                            <ModalVideo channel='youtube' isOpen={this.state.isOpen} videoId='ZOoVOfieAF8' onClose={() => this.setState({isOpen: false})} />
                                            <button className="video-popup position-top-center" onClick={this.openModal}><span className="play-icon"></span></button>
                                        </div>
                                        <p className="mb--0">There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injected humour, or randomised words which don't look even slightly believable. If you are going to use a passage of Lorem Ipsum. You need to be sure there isn't anything embarrassing hidden in the middle of text. All the Lorem Ipsum generators on the Internet tend toitrrepeat predefined chunks. Necessary, making this the first true generator on the Internet. It re are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form, by injectedeed eedhumour, or randomised words which don't look even slightly believable.</p> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Blog Details */}

        {/* Start BLog Comment Form  */}

        {/* End BLog Comment Form  */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default BlogDigitalMarketing;
