import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import PricingTableDynamic from "../blocks/PricingTableDynamic";

const PricingTableLeft = [
  {
    title: "text-center",
    class: "col-lg-4 col-md-6 col-12",
    price: "300",
    rmpricing: "rn-pricing active",
    priceDetail: "Empowering Digital Success",
    description: ["2-Pages Website", "1-revision", "Contact-Us Page"],
  },
  {
    title: "text-center",
    class: "col-lg-4 col-md-6 col-12",
    price: "300",
    rmpricing: "rn-pricing",
    priceDetail: "Empowering Digital Success",
    description: ["2-Pages Website", "1-revision", "Contact-Us Page"],
  },
  {
    title: "text-center",
    class: "col-lg-4 col-md-6 col-12",
    price: "300",
    rmpricing: "rn-pricing",
    priceDetail: "Empowering Digital Success",
    description: ["2-Pages Website", "1-revision", "Contact-Us Page"],
  },
];

class SDDigitalM extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Service Details" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">WEBSITE</h2>
                  <p>Digital Marketing</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}
        {/* <PricingTableDynamic ptl={PricingTableLeft} /> */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src="/assets/images/service/service-01.png"
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">
                            A Proactive Approach to Social Media Marketing
                          </h4>
                          <p>
                            Social media has revolutionized how businesses
                            connect with their customers. We leverage popular
                            platforms like Facebook, Instagram, Twitter,
                            LinkedIn, and others to create dynamic social media
                            campaigns that resonate with your audience and drive
                            engagement.
                          </p>
                          <h4 className="title">Optimize and Reach with SEO</h4>
                          <p>
                            We know the importance of ranking high on search
                            engine results. Our SEO experts employ white-hat SEO
                            practices, ensuring improved visibility, increased
                            website traffic, and sustainable online growth for
                            your brand.
                          </p>
                          <h4 className="title">
                            Captivate Audiences with Content Marketing
                          </h4>
                          <p>
                            Good content is the backbone of any digital
                            marketing strategy. We craft compelling,
                            SEO-friendly content that not only captivates your
                            audience but also communicates your brand values
                            effectively.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">
                            Pay-Per-Click (PPC) Advertising for Immediate Impact
                          </h4>
                          <p>
                            Want immediate results? Our PPC campaigns are
                            designed to deliver. By targeting the right
                            keywords, we ensure your ads reach potential
                            customers when they're most likely to convert.
                          </p>
                          <h4 className="title">
                            Elevate Your Brand with Social Media Optimization
                          </h4>
                          <p>
                            Our social media optimization services are focused
                            on enhancing your brand image on social media
                            platforms. We optimize your profiles, improve
                            content reach, and increase engagement, positioning
                            your brand as a leader in your industry.
                          </p>
                          <h4 className="title">Data-Driven Decision Making</h4>
                          <p>
                            At Webitech, we make data-driven decisions. We
                            leverage analytics to measure campaign performance,
                            gather insights, and refine our strategies, ensuring
                            optimal ROI for your digital marketing investment.
                            <br /> <br />
                            Summarizing our approach, "Webitech offers
                            comprehensive digital marketing services, leveraging
                            SEO, social media, and data analytics to boost your
                            online presence and growth."
                            <br /> <br />
                            Join forces with Webitech and leverage our digital
                            marketing prowess to steer your brand towards
                            digital success. Experience the Webitech difference
                            - let's create, optimize, and conquer the digital
                            landscape together!
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src="/assets/images/service/service-02.png"
                            alt="Service Images"
                          />
                          <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="ZOoVOfieAF8"
                            onClose={() => this.setState({ isOpen: false })}
                          />
                          <button
                            className="video-popup"
                            onClick={this.openModal}
                          >
                            <span className="play-icon"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default SDDigitalM;
