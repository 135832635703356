import React, { Component } from "react";
import {
  FiCast,
  FiLayers,

  FiMonitor,

  FiDroplet,

  FiSmartphone,

  FiPenTool,
} from "react-icons/fi";
import { Link } from "react-router-dom";

const ServiceList = [
  {
    icon: <FiLayers />,
    title: "Website Design",
    link: "SDWebsiteD",
    description:
      "Crafting the perfect website isn't just a task at Webitech; it's a creative pursuit that we relish. Our website design service is all about molding your unique vision into a digital masterpiece.",
  },
  {
    icon: <FiDroplet />,
    title: "Logo Design",
    link: "SDLogoD",
    description:
      "Creating a unique identity in today's saturated digital landscape can be a tall order. But with Webitech's exceptional logo design service, standing out is easy.",
  },
  {
    icon: <FiCast />,
    title: "SEO Services",
    link: "SDSEO",
    description:
      "In the bustling digital marketplace, standing out from the crowd can be daunting. With Webitech’s specialized SEO services, you can climb the search engine rankings and make your mark online.",
  },
  {
    icon: <FiSmartphone />,
    title: "Mobile App Development",
    link: "SDMAD",
    description:
      "In today's digital landscape, a mobile app is a direct channel that connects businesses to customers. At Webitech, we provide top-tier mobile app development services tailored to your business needs.",
  },
  {
    icon: <FiMonitor />,
    title: "Digital Marketing",
    link: "SDDigitalM",
    description:
      "In today's digital-first world, a powerful online presence is essential. At Webitech, our comprehensive digital marketing services are designed to boost your brand's visibility, engage your audience, and accelerate growth.",
  },
  {
    icon: <FiPenTool />,
    title: "Content Writing",
    link: "SDContentW",
    description:
      "Words hold immense power, especially when it comes to digital marketing. At Webitech, our SEO-friendly content writing services strike a perfect balance between engaging storytelling and search engine optimization.",
  },
];

class ServiceThree extends Component {
 
  render() {
    const { column } = this.props;
    const ServiceContent = ServiceList.slice(0, this.props.item);
 
    return (
      <React.Fragment>
        <div className="row">
          {ServiceContent.map((val, i) => (
            <div className={`${column}`} key={i}>
              <Link to={val.link}   onClick={() => {
    window.scroll({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }}>
                <div className="service service__style--2">
                  <div className="icon">{val.icon}</div>
                  <div className="content">
                    <h3 className="title">{val.title}</h3>
                    <p>{val.description}</p>
                  </div>
                </div>
              </Link>
            </div>
          ))}
        </div>
      </React.Fragment>
    );
  }
}
export default ServiceThree;
