import React, { Component } from "react";
//import { Link } from "react-router-dom";
import { FiX, FiMenu } from "react-icons/fi";
import Scrollspy from "react-scrollspy";
import { HashLink } from 'react-router-hash-link';
class Header extends Component {
  constructor(props) {
    super(props);
    this.menuTrigger = this.menuTrigger.bind(this);
    this.CLoseMenuTrigger = this.CLoseMenuTrigger.bind(this);
    //  this.subMetuTrigger = this.subMetuTrigger.bind(this);
    window.addEventListener("load", function () {
      console.log("All assets are loaded");
    });
  }

  menuTrigger() {
    document.querySelector(".header-wrapper").classList.toggle("menu-open");
  }

  CLoseMenuTrigger() {
    document.querySelector(".header-wrapper").classList.remove("menu-open");
  }

  stickyHeader() {}

  render() {
    window.addEventListener("scroll", function () {
      var value = window.scrollY;
      if (value > 100) {
        document.querySelector(".header--fixed").classList.add("sticky");
      } else {
        document.querySelector(".header--fixed").classList.remove("sticky");
      }
    });
    var elements = document.querySelectorAll(".has-droupdown > a");
    for (var i in elements) {
      if (elements.hasOwnProperty(i)) {
        elements[i].onclick = function () {
          this.parentElement
            .querySelector(".submenu")
            .classList.toggle("active");
          this.classList.toggle("open");
        };
      }
    }
    const { logo, color = "default-color" } = this.props;
    let logoUrl;
    if (logo === "light") {
      logoUrl = (
        <img
          style={{
            height: 80,
          }}
          src="/assets/images/logo/logo-light.svg"
          alt="Digital Agency"
        />
      );
    } else if (logo === "dark") {
      logoUrl = (
        <img
          style={{
            height: 100,
          }}
          src="/assets/images/logo/logo-dark.svg"
          alt="Digital Agency"
        />
      );
    } else if (logo === "symbol-dark") {
      logoUrl = (
        <img src="/assets/images/logo/logo-all-dark.svg" alt="Digital Agency" />
      );
    } else if (logo === "symbol-light") {
      logoUrl = (
        <img src="/assets/images/logo/logo-light.svg" alt="Digital Agency" />
      );
    } else {
      logoUrl = (
        <img
          style={{
            height: 100,
          }}
          src="/assets/images/logo/logo.svg"
          alt="Digital Agency"
        />
      );
    }

    return (
      <header
        className={`header-area  formobile-menu header--transparent header--fixed ${color}`}
      >
        <div className="header-wrapper" id="header-wrapper">
          <div className="header-left">
            <div className="logo">
              <a href="/">{logoUrl}</a>
            </div>
          </div>
          <div className="header-right">
            <nav className="mainmenunav d-lg-block">
              <Scrollspy
                className="mainmenu"
                // items={[
                //   "home",
                //   "about",
                //   "service",
                //   "portfolio",
                //   "blog",
                //   "contact",
                // ]}
                items={[
                  "home",
                  "about",
                  "service",
                    "blog",
                  "contact",
                ]}
                currentClassName="is-current"
                offset={-200}
              >
                <li>
                  {" "}
                  {/* <Link to="/">Home</Link> */}
                  <HashLink to="/" smooth >Home</HashLink>
                </li>

                <li>
                  {/* <Link to="/#about">About</Link> */}
                  <HashLink to="/#about" smooth >About</HashLink>
                </li>
                <li>
                  {/* <Link to="/#service">Service</Link> */}
                  <HashLink to="/#service" smooth >Service</HashLink>
                </li>
                {/* <li>
                  <Link to="/#portfolio">Portfolio</Link>
                </li> */}
                {/* <li><Link to="/#team">Team</Link></li>
                                    <li><Link to="/#testimonial">Testimonial</Link></li> */}
                <li>
                  {/* <Link to="/#blog">Blog</Link> */}
                  <HashLink to="/#blog" smooth >Blog</HashLink>
                </li>
                <li>
                  {/* <Link to="/#contact">Contact</Link> */}
                  <HashLink to="/#contact" smooth >Contact</HashLink>
                </li>
              </Scrollspy>
            </nav>
            {/* <div className="header-btn">
              <a className="rn-btn" href="#">
                <span>buy now</span>
              </a>
            </div> */}
            {/* Start Humberger Menu  */}
            <div className="humberger-menu d-block d-lg-none pl--20">
              <span
                onClick={this.menuTrigger}
                className="menutrigger text-white"
              >
                <FiMenu />
              </span>
            </div>
            {/* End Humberger Menu  */}
            <div className="close-menu d-block d-lg-none">
              <span onClick={this.CLoseMenuTrigger} className="closeTrigger">
                <FiX />
              </span>
            </div>
          </div>
        </div>
      </header>
    );
  }
}
export default Header;
