const BlogContent = [
    {
        images: '01',
        title: 'The Importance of SEO',
        category: 'Business',
        webpage:"/BlogSeo#mainTop",
    },
    {
        images: '02',
        title: 'The Power of Effective Web Design ',
        category: 'Creating a Lasting Digital Impression',
        webpage:"/BlogWebDesign",

    },

    {
        images: '03',
        title: ' Power of Digital Marketing',
        category: 'Strategies for Success',
        webpage:"/BlogDigitalMarketing",

    },
    {
        images: '04',
        title: ' Web Development',
        category: 'Tools, Technologies, and Best Practices',
        webpage:"/BlogWebDevelopment",

    },
]

export default BlogContent;