import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import PricingTableDynamic from "../blocks/PricingTableDynamic";

const PricingTableLeft = [
  {
    title: "text-center",
    class: "col-lg-4 col-md-6 col-12",
    price: "300",
    rmpricing: "rn-pricing active",
    priceDetail: "Empowering Digital Success",
    description: ["2-Pages Website", "1-revision", "Contact-Us Page"],
  },
  {
    title: "text-center",
    class: "col-lg-4 col-md-6 col-12",
    price: "300",
    rmpricing: "rn-pricing",
    priceDetail: "Empowering Digital Success",
    description: ["2-Pages Website", "1-revision", "Contact-Us Page"],
  },
  {
    title: "text-center",
    class: "col-lg-4 col-md-6 col-12",
    price: "300",
    rmpricing: "rn-pricing",
    priceDetail: "Empowering Digital Success",
    description: ["2-Pages Website", "1-revision", "Contact-Us Page"],
  },
];

class SDSEO extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Service Details" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">WEBITECH</h2>
                  <p>SEO Services</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}
        {/* <PricingTableDynamic ptl={PricingTableLeft} /> */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src="/assets/images/service/service-01.png"
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">Mastering Website Ranking</h4>
                          <p>
                            Search engine ranking isn't just about having a
                            website; it's about making your website visible,
                            relevant, and credible. At Webitech, we understand
                            the mechanics of website ranking. Our team of SEO
                            experts uses tried-and-true strategies, tailor-made
                            to give your website the prominence it deserves.
                          </p>
                          <h4 className="title">
                            Demystifying Search Engine Optimization
                          </h4>
                          <p>
                            Search engine optimization, or SEO, can seem
                            complicated. But don't worry, Webitech is here to
                            demystify it. We bring to the table an
                            easy-to-understand, results-oriented approach to
                            SEO. We navigate the complexity of keywords,
                            metadata, link-building, and on-page optimization to
                            deliver an SEO strategy that's just right for you.
                          </p>
                          <h4 className="title">Making SEO Work for You</h4>
                          <p>
                            SEO isn't a one-size-fits-all solution. It's a
                            complex, ever-evolving process that requires an
                            understanding of your business, your goals, and your
                            audience. At Webitech, we take the time to learn
                            about your brand and customize an SEO strategy that
                            aligns with your unique business needs.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">
                            The Power of SEO in Digital Marketing
                          </h4>
                          <p>
                            SEO is a key player in the realm of digital
                            marketing. It increases your website's visibility,
                            drives traffic, and helps you connect with your
                            target audience. With Webitech’s expertise, you can
                            harness the power of SEO and carve out a powerful
                            digital presence.
                          </p>
                          <h4 className="title">
                            Integrated and Sustainable SEO Solutions
                          </h4>
                          <p>
                            Webitech’s SEO solutions are not just effective but
                            also sustainable. Our strategies are designed with a
                            long-term perspective, ensuring your website
                            continues to rank well as search engine algorithms
                            evolve. We don’t just bring you to the top; we help
                            you stay there.
                            <br />
                            <br />
                            Here's a 16-word summary of our service: "Webitech’s
                            SEO services - driving your website ranking with
                            tailored, sustainable search engine optimization
                            strategies."
                            <br />
                            <br />
                            If you're ready to elevate your online presence and
                            reach new heights with your website, contact
                            Webitech. Let us guide you through the realm of SEO,
                            making it work for your unique brand and objectives.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src="/assets/images/service/service-02.png"
                            alt="Service Images"
                          />
                          <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="ZOoVOfieAF8"
                            onClose={() => this.setState({ isOpen: false })}
                          />
                          <button
                            className="video-popup"
                            onClick={this.openModal}
                          >
                            <span className="play-icon"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default SDSEO;
