import React, { Component } from "react";
import PageHelmet from "../component/common/Helmet";
import ModalVideo from "react-modal-video";
import ScrollToTop from "react-scroll-up";
import { FiChevronUp } from "react-icons/fi";
import Header from "../component/header/Header";
import Footer from "../component/footer/Footer";
import PricingTableDynamic from "../blocks/PricingTableDynamic";

const PricingTableLeft = [
  {
    title: "text-center",
    class: "col-lg-4 col-md-6 col-12",
    price: "300",
    rmpricing: "rn-pricing active",
    priceDetail: "Empowering Digital Success",
    description: ["2-Pages Website", "1-revision", "Contact-Us Page"],
  },
  {
    title: "text-center",
    class: "col-lg-4 col-md-6 col-12",
    price: "300",
    rmpricing: "rn-pricing",
    priceDetail: "Empowering Digital Success",
    description: ["2-Pages Website", "1-revision", "Contact-Us Page"],
  },
  {
    title: "text-center",
    class: "col-lg-4 col-md-6 col-12",
    price: "300",
    rmpricing: "rn-pricing",
    priceDetail: "Empowering Digital Success",
    description: ["2-Pages Website", "1-revision", "Contact-Us Page"],
  },
];

class SDLogoD extends Component {
  constructor() {
    super();
    this.state = {
      isOpen: false,
    };
    this.openModal = this.openModal.bind(this);
  }
  openModal() {
    this.setState({ isOpen: true });
  }
  render() {
    return (
      <React.Fragment>
        {/* Start Pagehelmet  */}
        <PageHelmet pageTitle="Service Details" />
        {/* End Pagehelmet  */}

        <Header
          headertransparent="header--transparent"
          colorblack="color--black"
          logoname="logo.png"
        />

        {/* Start Breadcrump Area */}
        <div
          className="rn-page-title-area pt--120 pb--190 bg_image bg_image--5"
          data-black-overlay="5"
        >
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="rn-page-title text-center pt--100">
                  <h2 className="title theme-gradient">WEBSITE DEVELOPMENT</h2>
                  <p>Fresh From The Press Discription</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Breadcrump Area */}
        {/* <PricingTableDynamic ptl={PricingTableLeft} /> */}

        {/* Start Page Wrapper */}
        <div className="rn-service-details ptb--120 bg_color--1">
          <div className="container">
            <div className="row">
              <div className="col-lg-12">
                <div className="service-details-inner">
                  <div className="inner">
                    {/* Start Single Area */}
                    <div className="row sercice-details-content pb--80 align-items-center">
                      <div className="col-lg-6 col-12">
                        <div className="thumb">
                          <img
                            className="w-100"
                            src="/assets/images/service/service-01.png"
                            alt="Service Images"
                          />
                        </div>
                      </div>
                      <div className="col-lg-6 col-12">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">
                            Crafting a Symbol, Creating an Identity
                          </h4>
                          <p>
                            A logo is not just an emblem or a symbol. It's the
                            embodiment of your brand's personality, values, and
                            aspirations. That's why our logo design process
                            involves a deep understanding of your brand and your
                            vision. At Webitech, we don't just create logos; we
                            forge identities.
                          </p>
                          <h4 className="title">
                            Balance Between Aesthetics and Meaning
                          </h4>
                          <p>
                            Striking the right balance between aesthetic appeal
                            and meaning is key to an effective logo. Our skilled
                            designers combine these elements beautifully,
                            creating logos that are visually striking and
                            profoundly meaningful. Your logo should narrate your
                            brand's story, and at Webitech, we ensure it does.
                          </p>
                          <h4 className="title">
                            Designing with Purpose and Precision
                          </h4>
                          <p>
                            Every logo we design is a result of meticulous
                            planning, thoughtful execution, and a keen eye for
                            detail. Our logo designs aren't just about being
                            visually pleasing; they're about resonating with
                            your audience, triggering a connection, and
                            establishing a memorable brand identity.
                          </p>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}

                    {/* Start Single Area */}
                    <div className="row sercice-details-content align-items-center">
                      <div className="col-lg-6 col-12 order-2 order-lg-1">
                        <div className="details mt_md--30 mt_sm--30">
                          <h4 className="title">Versatility in Logo Design</h4>
                          <p>
                            Our designers understand that your logo will
                            represent your brand across multiple platforms.
                            That's why we ensure that our designs are versatile,
                            adaptable, and consistent across various mediums.
                            Whether it's a business card, a website, or a
                            billboard, your logo will shine.
                          </p>
                          <h4 className="title">SEO-Integrated Approach</h4>
                          <p>
                            In today's digital world, having an SEO-integrated
                            approach to logo design is essential. We, at
                            Webitech, take this into account, optimizing your
                            logo to be easily recognizable and searchable
                            online. By incorporating SEO practices into our logo
                            design, we make your brand identity both distinctive
                            and discoverable.
                          </p>
                          <h4 className="title">The Power of a Logo</h4>
                          <p>
                            A 16-word snapshot of our service: "Webitech logo
                            design - striking, meaningful, versatile, and
                            SEO-optimized to showcase your brand's uniqueness."
                            <br />
                            Ready to give your brand a unique and unforgettable
                            identity? Dive into the world of Webitech's logo
                            design service and let us create a logo that truly
                            represents you.
                          </p>
                        </div>
                      </div>
                      <div className="col-lg-6 col-12 order-1 order-lg-2">
                        <div className="thumb position-relative">
                          <img
                            className="w-100"
                            src="/assets/images/service/service-02.png"
                            alt="Service Images"
                          />
                          <ModalVideo
                            channel="youtube"
                            isOpen={this.state.isOpen}
                            videoId="ZOoVOfieAF8"
                            onClose={() => this.setState({ isOpen: false })}
                          />
                          <button
                            className="video-popup"
                            onClick={this.openModal}
                          >
                            <span className="play-icon"></span>
                          </button>
                        </div>
                      </div>
                    </div>
                    {/* End Single Area */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* End Page Wrapper */}

        {/* Start Back To Top */}
        <div className="backto-top">
          <ScrollToTop showUnder={160}>
            <FiChevronUp />
          </ScrollToTop>
        </div>
        {/* End Back To Top */}

        <Footer />
      </React.Fragment>
    );
  }
}
export default SDLogoD;
